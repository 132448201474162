import service from '../../services/factory-service';
import Vue from 'vue';

const notifAppService = service.get("notification");

const state = { notif: {}, status: '', list: [], paginated: {} };

const getters = {
    notif: (notifState) => notifState.notif,
    list: (notifState) => notifState.list,
    paginated: (notifState) => notifState.paginated,
};

const actions = {
    getUserNotifications: ({commit}, payload) => {
        commit('notifRequest');
        notifAppService.getUserNotifications(payload)
        .then((result) => {
            commit('notifList', result.data);
        },
        (errors) => {
            commit('notifError', errors);
        });
    },
    getNotifications: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        commit('notifRequest');
        notifAppService.getNotifications(payload)
          .then((result) => {
            commit('notifPaginated', result.data);
            resolve();
          },
            (errors) => {
              commit('notifError', errors);
              reject();
            });
      });
    },
    updateStatus: ({commit}, id) => {
      commit('notifRequest');
      notifAppService.updateStatus(id)
      .then((result) => {
          commit('notifSuccess', result.data);
      },
      (errors) => {
          commit('notifError', errors);
      });
  },
};

const mutations = {
    notifRequest: (notifState) => {
        notifState.status = 'attempting request for notif data';
    },
    notifSuccess: (notifState, notifResp) => {
      notifState.status = 'success';
      Vue.set(notifState, 'notif', notifResp);
    },
    notifList: (notifState, list) => {
      notifState.status = 'success';
      Vue.set(notifState, 'list', list);
    },
    notifPaginated: (notifState, resp) => {
      notifState.status = 'success';
      Vue.set(notifState, 'paginated', resp);
    },
    notifError: (notifState) => {
      notifState.status = 'error';
    },
    notifLogout: (notifState) => {
      notifState.status = '';
  },
};

export default {
    state,
    getters,
    actions,
    mutations,
};