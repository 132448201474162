import Client from './clients/axios-client';
import { serialize } from 'object-to-formdata';
const resource = '/api/companyinvoice';

export default {
	createProjectInvoice(payload) {
		const _formData = serialize(payload);
		if (payload.Attachments !== undefined) {
			payload.Attachments.forEach((file) => { _formData.append('Attachments', file); });
		}

		return Client.post(`${resource}`, _formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
	},
	getInvoiceList(payload) {
		return Client.post(`${resource}/processing`, payload);
	},
	getClientInvoiceList(payload) {
		return Client.post(`${resource}/project/${payload.Size}/${payload.Index}`, payload.Body);
	},
	getProjectInvoice(id) {
		return Client.get(`${resource}/project/${id}`);
	},
	getInvoice(id) {
		return Client.get(`${resource}/${id}`);
	},
	getInvoiceForProcessing(id) {
		return Client.get(`${resource}/processing/${id}`);
	},
	downloadFile(payload) {
		return Client.get(`${resource}/${payload.id}/files/${payload.fileId}`, {
			responseType: 'blob'
		});
	},
	removeFile(payload) {
		return Client.delete(`${resource}/${payload.id}/files/${payload.fileId}`);
	},
	changeInvoiceStatusDiscussion(payload) {
		return Client.put(`${resource}/processing/${payload.id}`, payload);
	},
	uploadFile(payload) {
		const _formData = serialize(payload);
		if (payload.Attachments !== undefined) {
			payload.Attachments.forEach((file) => { _formData.append('Attachments', file); });
		}

		return Client.post(`${resource}/${payload.id}/files`, _formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
	},
	exportReport(payload) {
		return Client.post(`${resource}/export`, payload, {
			responseType: 'blob'
		});
	},
}