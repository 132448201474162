import AuthService from './auth-service';
import UserService from './user-service';
import SkillService from './skill-service';
import Project from './project-service';
import TeamService from './team-service';
import NotificationService from './notification-service';
import CompanyService from './company-service';
import IndustryService from './industry-service';
import SubscriptionService from './subscription-service';
import InvoiceService from './invoice-service';
import CompanyInvoiceService from './company-invoice-service';
import PaymentService from './payment-services';
import MessengerService from './messenger-service';
import HealthService from './health-service';
import CommunityService from './community-service';
import ReferrerService from './referrer-service';

const repositories = {
    'auth': AuthService,
    'users': UserService,
    'skills': SkillService,
    'project': Project,
    'team': TeamService,
    'notification': NotificationService,
    'company': CompanyService,
    'industry': IndustryService,
    'subscription': SubscriptionService,
    'invoice': InvoiceService,
    'companyinvoice': CompanyInvoiceService,
    'payment': PaymentService,
    'messenger': MessengerService,
    'healthcheck': HealthService,
    'community': CommunityService,
    'referrer': ReferrerService,
}
export default {
    get: name => repositories[name]
};