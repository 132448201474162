import Client from './clients/axios-client';
const resource = '/api/referrer';

export default {
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  get() {
    return Client.get(`${resource}`);
  },
  remove(id) {
    return Client.delete(`${resource}/${id}`);
  },
  edit(payload) {
    return Client.put(`${resource}/${payload.ID}`, payload);
  },
  getTally() {
    return Client.get(`${resource}/tally`);
  },
  getByCriteria(payload) {
    return Client.post(`${resource}/criteria`, payload);
  },
  getByName(val) {
    return Client.get(`${resource}/${val}`);
  },
  exportReport(payload) {
    return Client.post(`${resource}/export`, payload, {
			responseType: 'blob'
		});
  },
};