import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import store from './store/store';

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        //Dashboards
        {
          name: "Admin",
          path: "dashboards/admin",
          component: () => import("@/views/dashboards/admin/Home"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
        {
          name: "Talent",
          path: "dashboards/talent",
          component: () => import("@/views/dashboards/talent/Home"),
          meta: {
            allowedRoles: [ "Talent" ],
          },
        },
        {
          name: "Client",
          path: "dashboards/client",
          component: () => import("@/views/dashboards/client/Home"),
          meta: {
            allowedRoles: [ "Client" ],
          },
        },
        {
          name: "Limited",
          path: "dashboards/limited",
          component: () => import("@/views/dashboards/limited/Home"),
          meta: {
            allowedRoles: [ "Limited" ],
          },
        },
        //Users
        {
          name: "User List",
          path: "users/list",
          component: () => import("@/views/users/list/List"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
        //Notifications
        {
          name: "Notifications",
          path: "notification/list",
          component: () => import("@/views/notification/List"),
        },
        {
          name: "User For Approval",
          path: "users/forapproval",
          component: () => import("@/views/users/for-approval/ForApproval"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
        //Skills
        {
          name: "Skills",
          path: "skills/list",
          component: () => import("@/views/skills/Skills"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
        //Skills
        {
          name: "Community",
          path: "community/list",
          component: () => import("@/views/community/Index"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
         //Subscriptions
         {
          name: "Subscriptions",
          path: "subscription/list",
          component: () => import("@/views/subscription/List"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
        //Talent Invoice
        {
          name: "Talent Invoice",
          path: "invoice/talent",
          component: () => import("@/views/invoices/ReceivedInvoice"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
        {
          name: "Company Invoice",
          path: "invoice/company",
          component: () => import("@/views/invoices/ReleaseInvoice"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
        //Project
        {
          name: "Project",
          path: "project/list",
          component: () => import("@/views/projects/List"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
        {
          name: "Project For Approval",
          path: "project/forapproval",
          component: () => import("@/views/projects/ForApproval"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
        //Chat
        {
          name: "Chat",
          path: "/chat",
          component: () => import("@/views/chat/Chat"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
        // Profile
        {
          name: "Profile",
          path: "profile/account",
          component: () => import("@/views/profile/Profile"),
        },
        // Teams
        {
          name: "Team Projects",
          path: "teams/openproject",
          component: () => import("@/views/teams/PublishedProjects"),
        },
        {
          name: "Open Team",
          path: "teams/openteams",
          component: () => import("@/views/teams/OpenTeam"),
        },
        {
          name: "Project Details",
          path: "teams/project/details",
          component: () => import("@/views/teams/ProjectDetails"),
        },
        {
          name: "Team List",
          path: "teams/list",
          component: () => import("@/views/teams/List"),
        },
        {
          name: "Team Details",
          path: "teams/details",
          component: () => import("@/views/teams/TeamDetails"),
        },
        {
          name: "Team Project Invoice",
          path: "teams/project/invoice/details",
          component: () => import("@/views/teams/InvoicePage"),
          meta: {
            allowedRoles: [ "Talent" ],
          },
        },
        // Application
        {
          name: "Apps",
          path: "apps/chat",
          component: () => import("@/views/apps/chat/Chat"),
        },
        // Company
        {
          name: "Companies",
          path: "client/company",
          component: () => import("@/views/company/List"),
        },
        // Company Project
        {
          name: "Companies Project",
          path: "client/project",
          component: () => import("@/views/company/Project"),
        },
        {
          name: "Company Project Details",
          path: "client/project/details",
          component: () => import("@/views/company/ProjectDetails"),
        },
        {
          name: "CLient Project Invoice",
          path: "client/project/invoice/details",
          component: () => import("@/views/company/InvoicePage"),
          meta: {
            allowedRoles: [ "Client" ],
          },
        },
        //Transactions
        {
          name: "Transaction Invoice",
          path: "transaction/invoice",
          component: () => import("@/views/transaction/Invoices/Invoice"),
        },
        {
          name: "Transaction Payment",
          path: "transaction/Payment",
          component: () => import("@/views/transaction/Payments/Payment"),
        },
        {
          name: "Payment Success",
          path: "client/payment/success",
          component: () => import("@/views/payments/PaymentSuccess"),
        },
        {
          name: "Transactions",
          path: "transaction/list",
          component: () => import("@/views/transaction/List"),
          meta: {
            allowedRoles: [ "Client", "Admin" ],
          },
        },
        // Talent Transactions
        {
          name: "Talent Invoice Details",
          path: "invoice/talent/details",
          component: () => import("@/views/invoices/TalentInvoice"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
        // Client Transactions
        {
          name: "Client Invoice",
          path: "invoice/client/details",
          component: () => import("@/views/invoices/ClientInvoice"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
        // Referrer
        {
          name: "Referrer",
          path: "referrer/list",
          component: () => import("@/views/referrer/Index"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
         // Report
        {
          name: "Referrer Report",
          path: "report/referrer",
          component: () => import("@/views/reports/Referrer/Index"),
          meta: {
            allowedRoles: [ "Admin" ],
          },
        },
        // Payment Approval
        {
          name: "Stripe Payment Success",
          path: "stripe/success",
          component: () => import("@/views/payments/StripePayment"),
          meta: {
            allowedRoles: [ "Client" ],
          },
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/authentication",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Login",
          path: "login",
          component: () => import("@/views/authentication/FullLogin"),
        },
        {
          name: "Register",
          path: "register",
          component: () => import("@/views/authentication/FullRegister"),
        },
        {
          name: "Error",
          path: "error",
          component: () => import("@/views/authentication/Error"),
        },
        {
          name: "VerifyEmail",
          path: "/verifyemail",
          component: () => import("@/views/authentication/VerifyEmail"),
        },
        {
          name: "ForgotPassword",
          path: "forgotpassword",
          component: () => import("@/views/authentication/ForgotPassword"),
        },
        {
          name: "MsalValidation",
          path: "msalvalidation",
          component: () => import("@/views/authentication/MsalValidation"),
        },
      ],
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters['auth/isAuthenticated']) {
      store.dispatch('auth/authLogout').then(() => {
        next({
          path: '/authentication/login',
          query: { redirect: to.fullPath },
        });
      });
    } else {
      if (to.matched.some((record) => record.meta.allowedRoles)) {
        if(!to.meta.allowedRoles.includes(store.getters['auth/authType'])) {
          store.dispatch('auth/authLogout').then(() => {
            next({
              path: '/authentication/login',
              query: { redirect: to.fullPath },
            });
          });
        } else {
          next();
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
