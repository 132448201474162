import Client from './clients/axios-client';
import { serialize } from 'object-to-formdata';
const resource = '/api/payment';

export default {
	submitPayment(payload) {
		const _formData = serialize(payload);
		if (payload.Attachments !== undefined) {
			payload.Attachments.forEach((file) => { _formData.append('Attachments', file); });
		}

		return Client.post(`${resource}`, _formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
	},
	releasePayment(payload) {
		const _formData = serialize(payload);
		if (payload.Attachments !== undefined) {
			payload.Attachments.forEach((file) => { _formData.append('Attachments', file); });
		}

		return Client.post(`${resource}/release`, _formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
	},
	getClientPaymentList(payload) {
		return Client.post(`${resource}/project/${payload.Index}/${payload.Size}`, payload.Body);
	},
	getPaymentByInvoice(id) {
		return Client.get(`${resource}/project/${id}`);
	},
	getPayment(id) {
		return Client.get(`${resource}/${id}`);
	},
	getTransactions(payload) {
		return Client.post(`${resource}/transactions`, payload);
	},
	downloadFile(payload) {
		return Client.get(`${resource}/${payload.id}/files/${payload.fileId}`, {
			responseType: 'blob'
		});
	},
	confirmPayment(id) {
		return Client.patch(`${resource}/${id}/confirmed`);
	},
	conflictPayment(payload) {
		return Client.patch(`${resource}/${payload.id}/conflict`, { Remarks: payload.body });
	},
	exportReport(payload) {
		return Client.post(`${resource}/export`, payload, {
			responseType: 'blob'
		});
	},
	paymentIntent(id) {
		return Client.get(`${resource}/intent/${id}`);
	},
}