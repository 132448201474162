import service from '../../services/factory-service';
import Vue from 'vue';

const invoiceService = service.get("invoice");
const companyInvoiceService = service.get("companyinvoice");
const paymentService = service.get("payment")

const state = { invoice: {}, status: '', list: [], paginated: {}, criteria: {} };

const getters = {
  invoice: (invoiceState) => invoiceState.invoice,
  list: (invoiceState) => invoiceState.list,
  status: (invoiceState) => invoiceState.status,
  paginated: (invoiceState) => invoiceState.paginated,
  criteria: (invoiceState) => invoiceState.criteria,
};

const actions = {
  createProjectInvoice: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      invoiceService.createProjectInvoice(payload)
        .then((result) => {
          commit('invoiceList', result.data);
          resolve();
        },
          (errors) => {
            commit('invoiceError', errors);
            reject(errors);
          });
    });
  },
  updateProjectInvoice: ({ commit }, payload) => {
    commit('invoiceRequest');
    invoiceService.updateProjectInvoice(payload)
      .then((result) => {
        commit('invoiceSuccess', result.data);
      },
        (errors) => {
          commit('invoiceError', errors);
        });
  },
  saveInvoiceDiscussion: ({ commit }, payload) => {
    commit('invoiceRequest');
    invoiceService.saveInvoiceDiscussion(payload)
      .then((result) => {
        commit('invoiceSuccess', result.data);
      },
        (errors) => {
          commit('invoiceError', errors);
        });
  },
  getProjectInvoice: ({ commit }, id) => {
    commit('invoiceRequest');
    invoiceService.getProjectInvoice(id)
      .then((result) => {
        commit('invoiceList', result.data);
      },
        (errors) => {
          commit('invoiceError', errors);
        });
  },
  getTalentInvoice: ({ commit }, id) => {
    commit('invoiceRequest');
    invoiceService.getTalentInvoice(id)
      .then((result) => {
        commit('invoiceSuccess', result.data);
      },
        (errors) => {
          commit('invoiceError', errors);
        });
  },
  getProcessingInvoice: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      invoiceService.getInvoiceListForProcessing(payload)
        .then((result) => {
          commit('invoicePaginated', result.data);
          resolve();
        },
          (errors) => {
            commit('invoiceError', errors);
            reject();
          });
    });
  },
  getInvoiceForProcessing: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      invoiceService.getInvoiceForProcessing(id)
        .then((result) => {
          commit('invoiceSuccess', result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('invoiceError', errors);
            reject();
          });
    });
  },
  downloadFile: ({ commit }, payload) => {
    commit('invoiceRequest');
    invoiceService.downloadFile(payload)
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', payload.name);
        document.body.appendChild(link);
        link.click();
      },
        (errors) => {
          commit('invoiceError', errors);
        });
  },
  removeFile: ({ commit }, payload) => {
    commit('invoiceRequest');
    invoiceService.removeFile(payload)
      .then((result) => {
        commit('invoiceSuccess', result.data);
      },
        (errors) => {
          commit('invoiceError', errors);
        });
  },
  changeStatus: ({ commit }, payload) => {
    commit('invoiceRequest');
    invoiceService.changeInvoiceStatusDiscussion(payload)
      .then((result) => {
        commit('invoiceSuccess', result.data);
      },
        (errors) => {
          commit('invoiceError', errors);
        });
  },
  createCompanyInvoice: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      companyInvoiceService.createProjectInvoice(payload)
        .then((result) => {
          commit('invoiceSuccess', result.data);
          resolve();
        },
          (errors) => {
            commit('invoiceError', errors);
            reject(errors);
          });
    });
  },
  releasePayment: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      paymentService.releasePayment(payload)
        .then((result) => {
          commit('invoiceSuccess', result.data);
          resolve();
        },
          (errors) => {
            commit('invoiceError', errors);
            reject(errors);
          });
    });
  },
  saveCriteria: ({ commit }, payload) => {
    localStorage.setItem(payload.key, JSON.stringify(payload.content));
    commit('invoiceCriteria', payload.content);
  },
  getCriteria: ({ commit }, key) => {
    let criteria = JSON.parse(localStorage.getItem(key));

    if (criteria === undefined || criteria == null) {
      criteria = {
        invoiceno: null,
        status: null,
        startDate: null,
        endDate: null,
        companies: [],
        projects: [],
      };
    }

    commit('invoiceCriteria', criteria);
  },
  resetCriteria: ({ commit }, key) => {
    let criteria = {
      invoiceno: null,
      status: null,
      startDate: null,
      endDate: null,
      companies: [],
      projects: [],
    };

    localStorage.setItem(key, JSON.stringify(criteria));
    commit('invoiceCriteria', criteria);
  },
  exportReport: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      invoiceService.exportReport(payload)
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Talent_Invoice_Received.xlsx');
          document.body.appendChild(link);
          link.click();
          resolve();
        },
          (errors) => {
            commit('invoiceError', errors);
            reject();
          });
    });
  },
};

const mutations = {
  invoiceRequest: (invoiceState) => {
    invoiceState.status = 'attempting request for invoice data';
  },
  invoiceSuccess: (invoiceState, response) => {
    invoiceState.status = 'success';
    Vue.set(invoiceState, 'invoice', response);
  },
  invoiceList: (invoiceState, list) => {
    invoiceState.status = 'success';
    Vue.set(invoiceState, 'list', list);
  },
  invoicePaginated: (invoiceState, list) => {
    invoiceState.status = 'success';
    Vue.set(invoiceState, 'paginated', list);
  },
  invoiceError: (invoiceState, errors) => {
    console.debug(errors);
    invoiceState.status = 'error';
  },
  invoiceCriteria: (invoiceState, criteria) => {
    Vue.set(invoiceState, 'criteria', criteria);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};