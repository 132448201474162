import Client from './clients/axios-client';
const resource = '/api/companies';

export default {
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  getList() {
    return Client.get(`${resource}`);
  },
  get(id) {
    return Client.get(`${resource}/${id}`);
  },
  update(payload) {
    return Client.put(`${resource}/${payload.ID}`, payload);
  },
  getByIndustry(id) {
    return Client.get(`${resource}/industry/${id}`);
  },
  activate(id) {
    return Client.patch(`${resource}/${id}/activate`);
  },
  deactivate(id) {
    return Client.patch(`${resource}/${id}/deactivate`);
  },
  getByUserId() {
    return Client.get(`${resource}/user`);
  },
  getCoordinator(id) {
    return Client.get(`${resource}/coordinator/${id}`);
  },
  getCompanyByName(val) {
    return Client.get(`${resource}/name/${val}`);
  },
  getRating(id) {
    return Client.get(`${resource}/rating/${id}`);
  },
};