import service from '../../services/factory-service';
import Vue from 'vue';

const subscriptionService = service.get("subscription");

const state = { 
  subscription: {
    StripeProductId: "",
  }, 
  status: '', 
  list: [], 
  paymentIntent: {},
};

const getters = {
	subscription: (subscriptionState) => subscriptionState.subscription,
  list: (subscriptionState) => subscriptionState.list,
  paymentIntent: (subscriptionState) => subscriptionState.paymentIntent,
};

const actions = {
  getAllSubscriptions: ({ commit }) => {
    commit('subscriptionRequest');
    subscriptionService.getAllSubscriptions()
      .then((result) => {
        commit('subscriptionList', result.data);
      },
        (errors) => {
          commit('subscriptionError', errors);
        });
  },
	getPublishedSubscriptions: ({ commit }) => {
    commit('subscriptionRequest');
    subscriptionService.getPublishedSubscriptions()
      .then((result) => {
        commit('subscriptionList', result.data);
      },
        (errors) => {
          commit('subscriptionError', errors);
        });
  },
  createSubscription: ({commit}, payload) => {
    commit('subscriptionRequest');
    subscriptionService.createSubscription(payload)
      .then((result) => {
        commit('subscriptionList', result.data);
      },
        (errors) => {
          commit('subscriptionError', errors);
        });
  },
  editSubscription: ({commit}, payload) => {
    commit('subscriptionRequest');
    subscriptionService.editSubscription(payload)
      .then((result) => {
        commit('subscriptionList', result.data);
      },
        (errors) => {
          commit('subscriptionError', errors);
        });
  },
  updateSubscriptionStatus: ({commit}, payload) => {
    commit('subscriptionRequest');
    subscriptionService.updateSubscriptionStatus(payload)
      .then((result) => {
        commit('subscriptionList', result.data);
      },
        (errors) => {
          commit('subscriptionError', errors);
        });
  },
  getUserSubscription: ({commit}) => {
    commit('subscriptionRequest');
    subscriptionService.getUserSubscription()
      .then((response) => {
        commit('subscriptionSuccess', response.data);
      },
        (errors) => {
          commit('subscriptionError', errors);
        });
  },
  userSubscribe: ({commit}, payload) => {
    commit('subscriptionRequest');
    subscriptionService.userSubscribe(payload)
      .then(() => {
        commit('subscriptionSuccess', null);
      },
        (errors) => {
          commit('subscriptionError', errors);
        });
  },
  userUnsubscribe: ({commit}, payload) => {
    commit('subscriptionRequest');
    subscriptionService.userUnsubscribe(payload)
      .then(() => {
        commit('subscriptionSuccess', null);
      },
        (errors) => {
          commit('subscriptionError', errors);
        });
  },
};

const mutations = {
  subscriptionRequest: (subscriptionState) => {
    subscriptionState.status = 'attempting request for subscription profile data';
  },
  subscriptionSuccess: (subscriptionState, response) => {
    subscriptionState.status = 'success';
    Vue.set(subscriptionState, 'subscription', response);
  },
  subscriptionList: (subscriptionState, list) => {
    subscriptionState.status = 'success';
    Vue.set(subscriptionState, 'list', list);
  },
  subscriptionError: (subscriptionState) => {
    subscriptionState.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};