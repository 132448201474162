import Client from './clients/axios-client';
const resource = '/api/notification';

export default {
  getUserNotifications(payload) {
    return Client.get(`${resource}/${payload.index}/${payload.size}/${payload.userId}`);
  },
  getNotifications(payload) {
    return Client.get(`${resource}/list?size=${payload.Size}&index=${payload.Index}`);
  },
  updateStatus(id) {
    return Client.patch(`${resource}/${id}`);
  },
};