import service from '../../services/factory-service';
import Vue from 'vue';

const industryService = service.get("industry");

const state = { industry: {}, status: '', list: [] };

const getters = {
    industry: (industryState) => industryState.industry,
    list: (industryState) => industryState.list,
};

const actions = {
    add: ({ commit, dispatch }, industry) => {
		commit('industryRequest');
		industryService.create(industry)
			.then(() => {
				commit('industrySuccess', industry);
				dispatch('industry/get', null, { root: true });
			},
				(errors) => {
					commit('industryError', errors);
				});
	},
    get: ({commit}) => {
        commit('industryRequest');
        industryService.get()
        .then((result) => {
            commit('industryList', result.data);
        },
        (errors) => {
            commit('industryError', errors);
        });
    },
    activate: ({ commit, dispatch }, id) => {
		commit('industryRequest');
		industryService.activate(id)
			.then(() => {
				commit('industrySuccess', null);
				dispatch('industry/get', null, { root: true });
			},
				(errors) => {
					commit('industryError', errors);
				});
	},
	deactivate: ({ commit, dispatch }, id) => {
		commit('industryRequest');
		industryService.deactivate(id)
			.then(() => {
				commit('industrySuccess', null);
				dispatch('industry/get', null, { root: true });
			},
				(errors) => {
					commit('industryError', errors);
				});
    },
    remove: ({ commit, dispatch }, id) => {
		commit('industryRequest');
		industryService.delete(id)
			.then(() => {
				commit('industrySuccess', null);
				dispatch('industry/get', null, { root: true });
			},
				(errors) => {
					commit('industryError', errors);
				});
	},
};

const mutations = {
    industryRequest: (industryState) => {
        industryState.status = 'attempting request for industry data';
    },
    industrySuccess: (industryState, industryResp) => {
      industryState.status = 'success';
      Vue.set(industryState, 'industry', industryResp);
    },
    industryList: (industryState, list) => {
      industryState.status = 'success';
      Vue.set(industryState, 'list', list);
    },
    industryError: (industryState) => {
      industryState.status = 'error';
    },
    industryLogout: (industryState) => {
      industryState.status = '';
  },
};

export default {
    state,
    getters,
    actions,
    mutations,
};