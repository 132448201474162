import Client from './clients/axios-client';
const resource = '/api/community';

export default {
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  get() {
    return Client.get(`${resource}`);
  },
  getActive() {
    return Client.get(`${resource}/list`);
  },
  update(payload) {
    return Client.put(`${resource}/${payload.id}`, payload.body);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
};