import service from "../../services/factory-service";
import Vue from "vue";

const paymentService = service.get("payment");

const state = { payment: {}, status: "", list: [], paginated: {}, criteria: {}, intent: {} };

const getters = {
  payment: (paymentState) => paymentState.payment,
  list: (paymentState) => paymentState.list,
  status: (paymentState) => paymentState.status,
  loadingStatus: (paymentState) => paymentState.loadingStatus,
  paginated: (paymentState) => paymentState.paginated,
  criteria: (paymentState) => paymentState.criteria,
  intent: (paymentState) => paymentState.intent,
};

const actions = {
  getClientPaymentList: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("paymentRequest");
      commit("loadingPayment", true);
      paymentService.getClientPaymentList(payload).then(
        (result) => {
          commit("pagenatedList", result.data);
          commit("loadingPayment", false);
          resolve();
        },
        (errors) => {
          commit("paymentError", errors);
          commit("loadingPayment", false);
          reject();
        }
      );
    });
  },
  getPaymentByInvoice: ({ commit }, id) => {
    commit('paymentRequest');
    commit('paymentListReset');
    paymentService.getPaymentByInvoice(id)
      .then((result) => {
        commit('paymentList', result.data);
      },
        (errors) => {
          commit('paymentError', errors);
        });
  },
  getTransactions: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("paymentRequest");
      paymentService.getTransactions(payload).then(
        (result) => {
          commit("pagenatedList", result.data);
          resolve();
        },
        (errors) => {
          commit("paymentError", errors);
          reject();
        }
      );
    });
  },
  getPayment: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit("paymentRequest");
      paymentService.getPayment(id).then(
        (result) => {
          commit("paymentSuccess", result.data);
          resolve(result.data);
        },
        (errors) => {
          commit("paymentError", errors);
          reject();
        }
      );
    });
  },
  downloadFile: ({ commit }, payload) => {
    commit('paymentRequest');
    paymentService.downloadFile(payload)
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', payload.name);
        document.body.appendChild(link);
        link.click();
      },
        (errors) => {
          commit('paymentError', errors);
        });
  },
  confirmPayment: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit("paymentRequest");
      paymentService.confirmPayment(id).then(
        (result) => {
          commit("paymentSuccess", result.data);
          resolve();
        },
        (errors) => {
          commit("paymentError", errors);
          reject();
        }
      );
    });
  },
  conflictPayment: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("paymentRequest");
      paymentService.conflictPayment(payload).then(
        (result) => {
          commit("paymentSuccess", result.data);
          resolve();
        },
        (errors) => {
          commit("paymentError", errors);
          reject();
        }
      );
    });
  },
  exportReport: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('paymentRequest');
      paymentService.exportReport(payload)
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Payment_Transactions.xlsx');
          document.body.appendChild(link);
          link.click();
          resolve();
        },
          (errors) => {
            commit('paymentError', errors);
            reject();
          });
    });
  },
  getPaymentIntent: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit('paymentRequest');
      paymentService.paymentIntent(id)
        .then((result) => {
          commit("paymentIntent", result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('paymentError', errors);
            reject();
          });
    });
  },
};

const mutations = {
  paymentRequest: (paymentState) => {
    paymentState.status = "attempting request for payment data";
  },
  paymentSuccess: (paymentState, response) => {
    paymentState.status = "success";
    Vue.set(paymentState, "payment", response);
  },
  paymentList: (paymentState, list) => {
    paymentState.status = "success";
    Vue.set(paymentState, "list", list);
  },
  pagenatedList: (paymentState, list) => {
    paymentState.status = "success";
    Vue.set(paymentState, "paginated", list);
  },
  paymentListReset: (paymentState) => {
    paymentState.status = 'success';
    Vue.set(paymentState, 'list', []);
  },
  paymentError: (paymentState, errors) => {
    console.debug(errors);
    paymentState.status = "error";
  },
  paymentIntent: (paymentState, response) => {
    paymentState.status = "success";
    Vue.set(paymentState, "intent", response);
  },
  loadingPayment: (paymentState, response) => {
    paymentState.loadingStatus = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};