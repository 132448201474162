import Client from './clients/axios-client';
const resource = '/api/industries';

export default {
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  get() {
    return Client.get(`${resource}/true`);
  },
  activate(id) {
    return Client.patch(`${resource}/${id}/activate`);
  },
  deactivate(id) {
    return Client.patch(`${resource}/${id}/deactivate`);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
};