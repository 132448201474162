import Vue from "vue";
import Vuex from "vuex";

import auth from './modules/auth-module';
import user from './modules/user-module';
import skill from './modules/skill-module';
import project from './modules/project-module';
import team from './modules/team-module';
import notif from './modules/notif-module';
import company from './modules/company-module';
import industry from './modules/industry-module';
import subscription from './modules/subscription-modules';
import invoice from './modules/invoice-module';
import companyinvoice from './modules/company-invoice-module';
import payment from './modules/payment-module';
import messenger from './modules/messenger-module';
import healthcheck from './modules/health-module';
import createPersistedState from 'vuex-persistedstate';
import community from './modules/community-module';
import referrer from './modules/referrer-module';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "#1d2228", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "white",
    setHorizontalLayout: false, // Horizontal layout
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
  },
  actions: {},
  getters: {},
  modules: {
    auth: {
      namespaced: true,
      state: auth.state,
      mutations: auth.mutations,
      getters: auth.getters,
      actions: auth.actions,
    },
    user: {
      namespaced: true,
      state: user.state,
      actions: user.actions,
      mutations: user.mutations,
      getters: user.getters,
    },
    skill: {
      namespaced: true,
      state: skill.state,
      actions: skill.actions,
      mutations: skill.mutations,
      getters: skill.getters,
    },
    project: {
      namespaced: true,
      state: project.state,
      actions: project.actions,
      mutations: project.mutations,
      getters: project.getters,
    },
    team: {
      namespaced: true,
      state: team.state,
      actions: team.actions,
      mutations: team.mutations,
      getters: team.getters,
    },
    notif: {
      namespaced: true,
      state: notif.state,
      actions: notif.actions,
      mutations: notif.mutations,
      getters: notif.getters,
    },
    company: {
      namespaced: true,
      state: company.state,
      actions: company.actions,
      mutations: company.mutations,
      getters: company.getters,
    },
    industry: {
      namespaced: true,
      state: industry.state,
      actions: industry.actions,
      mutations: industry.mutations,
      getters: industry.getters,
    },
    subscription: {
      namespaced: true,
      state: subscription.state,
      actions: subscription.actions,
      mutations: subscription.mutations,
      getters: subscription.getters,
    },
    invoice: {
      namespaced: true,
      state: invoice.state,
      actions: invoice.actions,
      mutations: invoice.mutations,
      getters: invoice.getters,
    },
    companyinvoice: {
      namespaced: true,
      state: companyinvoice.state,
      actions: companyinvoice.actions,
      mutations: companyinvoice.mutations,
      getters: companyinvoice.getters,
    },
    payment: {
      namespaced: true,
      state: payment.state,
      actions: payment.actions,
      mutations: payment.mutations,
      getters: payment.getters,
    },
    messenger: {
      namespaced: true,
      state: messenger.state,
      actions: messenger.actions,
      mutations: messenger.mutations,
      getters: messenger.getters,
    },
    healthcheck: {
      namespaced: true,
      state: healthcheck.state,
      actions: healthcheck.actions,
      mutations: healthcheck.mutations,
      getters: healthcheck.getters,
    },
    community : {
      namespaced: true,
      state: community.state,
      actions: community.actions,
      mutations: community.mutations,
      getters: community.getters,
    },
    referrer: {
      namespaced: true,
      state: referrer.state,
      actions: referrer.actions,
      mutations: referrer.mutations,
      getters: referrer.getters,
    },
  },
});
