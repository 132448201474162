import service from '../../services/factory-service';
import Vue from 'vue';

const communityService = service.get("community");

const state = { community: {}, status: '', list: [] };

const getters = {
	community: (communityState) => communityState.community,
	list: (communityState) => communityState.list,
};

const actions = {
	add: ({ commit }, community) => {
		commit('communityRequest');
		communityService.create(community)
			.then((result) => {
				commit('communityList', result.data);
			},
				(errors) => {
					commit('communityError', errors);
				});
	},
	update: ({ commit }, payload) => {
		commit('communityRequest');
		communityService.update(payload)
			.then((result) => {
				commit('communityList', result.data);
			},
				(errors) => {
					commit('communityError', errors);
				});
	},
	delete: ({ commit }, id) => {
		commit('communityRequest');
		communityService.delete(id)
			.then((result) => {
				commit('communityList', result.data);
			},
				(errors) => {
					commit('communityError', errors);
				});
	},
	get: ({ commit }) => {
		commit('communityRequest');
		communityService.get()
			.then((result) => {
				commit('communityList', result.data);
			},
				(errors) => {
					commit('communityError', errors);
				});
	},
	getActive: ({ commit }) => {
		commit('communityRequest');
		communityService.getActive()
			.then((result) => {
				commit('communityList', result.data);
			},
				(errors) => {
					commit('communityError', errors);
				});
	},
};

const mutations = {
	communityRequest: (communityState) => {
		communityState.status = 'attempting request for community data';
	},
	communitySuccess: (communityState, communityResp) => {
		communityState.status = 'success';
		Vue.set(communityState, 'community', communityResp);
	},
	communityList: (communityState, list) => {
		communityState.status = 'success';
		Vue.set(communityState, 'list', list);
	},
	communityError: (communityState) => {
		communityState.status = 'error';
	},
	communityLogout: (communityState) => {
		communityState.status = '';
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};