import Client from './clients/axios-client';
const resource = '/api/teams';

export default {
  getUserTeams() {
    return Client.get(`${resource}/user-id`);
  },
  getUserTeamCount() {
    return Client.get(`${resource}/user-id/count`);
  },
  getUserTeamsMatching(payload) {
    return Client.post(`${resource}/user-id/withmatch`, payload);
  },
  getActiveTeams(statusid) {
    return Client.get(`${resource}/active/${statusid}`);
  },
  getTeam(id) {
    return Client.get(`${resource}/full/${id}`);
  },
  getTeamRating(id) {
    return Client.get(`${resource}/${id}/rating`);
  },
  createTeam(payload) {
    return Client.post(`${resource}`, payload);
  },
  updateTeam(payload) {
    return Client.put(`${resource}/${payload.id}`, payload);
  },
  updateTeamAvailability(payload) {
    return Client.put(`${resource}/${payload.id}/availability`, payload.body);
  },
  deleteTeam(id) {
    return Client.delete(`${resource}/${id}`);
  },
  activateTeam(id) {
    return Client.delete(`${resource}/${id}/activate`);
  },
  deactivateTeam(id) {
    return Client.delete(`${resource}/${id}/deactivate`);
  },
  addTeamMember(payload) {
    return Client.post(`${resource}/${payload.id}/members`, payload.body);
  },
  removeTeamMember(payload) {
    return Client.put(`${resource}/${payload.id}/members`, payload.body);
  },
  getCoordinator(id) {
    return Client.get(`${resource}/${id}/coordinator`);
  },
  getOpenTeams(payload) {
    return Client.post(`${resource}/open`, payload);
  },
  openMyTeam(id) {
    return Client.put(`${resource}/open`, { id: id });
  },
  applyOnATeam(id) {
    return Client.patch(`${resource}/open/${id}/members`);
  },
  removeMemberApplication(payload) {
    return Client.put(`${resource}/open/${payload.id}/members`, payload.body);
  },
  addFillerMember(payload) {
    return Client.post(`${resource}/${payload.id}/filler`, payload.body);
  },
  updateFillterMemberSkills(payload) {
    return Client.put(`${resource}/${payload.id}/filler`, payload.body);
  },
};