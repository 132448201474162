import Client from './clients/axios-client';
const resource = '/api/subscription';

export default {
  getAllSubscriptions() {
    return Client.get(`${resource}`);
  },
  getPublishedSubscriptions() {
    return Client.get(`${resource}/client`);
  },
  createSubscription(payload) {
    return Client.post(`${resource}`, payload);
  },
  editSubscription(payload) {
    return Client.put(`${resource}`, payload);
  },
  updateSubscriptionStatus(payload) {
    return Client.put(`${resource}/status`, payload);
  },
  getUserSubscription() {
    return Client.get(`${resource}/client/subscribe`);
  },
  userSubscribe(payload) {
    return Client.post(`${resource}/client/subscribe`, payload);
  },
  userUnsubscribe(payload) {
    return Client.post(`${resource}/client/unsubscribe`, payload);
  },
};