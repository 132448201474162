import Client from './clients/axios-client';
const resource = '/api/skill';

export default {
  getSkillsByStatusId(statusId) {
    return Client.get(`${resource}/skillsByStatus/${statusId}`);
  },
  getSkills() {
    return Client.get(`${resource}`);
  },
  getSkillsBatch(params) {
    return Client.get(`${resource}/skills/${params.index}/${params.size}/${params.statusId}`);
  },
  createSkill(payload) {
    return Client.post(`${resource}/skills/`, payload);
  },
  approveSkill(id) {
    return Client.patch(`${resource}/skills/${id}/activate`);
  },
  rejectSkill(id) {
    return Client.delete(`${resource}/skills/${id}`);
  },
  removeSkill(id) {
    return Client.delete(`${resource}/skills/${id}`);
  },
  editSkill(payload) {
    return Client.put(`${resource}/skills/${payload.ID}`, payload);
  }
};