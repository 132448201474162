import service from '../../services/factory-service';
import Vue from 'vue';

const companyService = service.get("company");
const userService = service.get("users");

const state = { 
	company: {}, 
	status: '', 
	list: [],
	members: [],
	loadingStatus: false,
	coordinator: {},
	rating: 0,
};

const getters = {
	company: (companyState) => companyState.company,
	list: (companyState) => companyState.list,
	members: (companyState) => companyState.members,
	loadingStatus: (companyState) => companyState.loadingStatus,
	coordinator: (companyState) => companyState.coordinator,
	rating: (companyState) => companyState.rating,
};

const actions = {
	addCompany: ({ commit }, company) => {
		commit('companyRequest');
		companyService.create(company)
			.then((result) => {
				commit('companyList', result.data);
			},
				(errors) => {
					commit('companyError', errors);
				});
	},
	getList: ({ commit }) => {
		commit('companyRequest');
		companyService.getList()
			.then((result) => {
				commit('companyList', result.data);
			},
				(errors) => {
					commit('companyError', errors);
				});
	},
	get: ({ commit }, id) => {
		commit('companyRequest');
		companyService.get(id)
			.then((result) => {
				commit('companySuccess', result.data);
			},
				(errors) => {
					commit('companyError', errors);
				});
	},
	getByIndustry: ({ commit }, id) => {
		commit('companyRequest');
		companyService.getByIndustry(id)
			.then((result) => {
				commit('companyList', result.data);
			},
				(errors) => {
					commit('companyError', errors);
				});
	},
	getByUserId: ({ commit }) => {
		commit('companyRequest');
		commit('companyLoading', true);
		companyService.getByUserId()
			.then((result) => {
				commit('companyList', result.data || []);
				commit('companyLoading', false);
			},
				(errors) => {
					commit('companyError', errors);
					commit('companyLoading', false);
				});
	},
	update: ({ commit }, payload) => {
		commit('companyRequest');
		companyService.update(payload)
			.then(() => {
				commit('companySuccess', payload);
			},
				(errors) => {
					commit('companyError', errors);
				});
	},
	activate: ({ commit, dispatch }, id) => {
		commit('companyRequest');
		companyService.activate(id)
			.then(() => {
				commit('companySuccess', null);
				dispatch('company/get', null, { root: true });
			},
				(errors) => {
					commit('companyError', errors);
				});
	},
	deactivate: ({ commit }, id) => {
		commit('companyRequest');
		companyService.deactivate(id)
			.then((result) => {
				commit('companyList', result.data);
			},
				(errors) => {
					commit('companyError', errors);
				});
	},
	searchMembers: ({commit}, name) => {
		commit('companyRequest');
		userService.searchClient(name)
			.then((result) => {
				commit('clientMembers', result.data);
			},
			(errors) => {
				commit('companyError', errors);
			})
	},
	addTeamMember: ({commit}, payload) => {
		commit('companyRequest');
		companyService.addTeamMember(payload)
			.then((result) => {
				commit('companySuccess', result.data);
			},
			(errors) => {
				commit('companyError', errors);
			})
	},
	removeTeamMember: ({commit}, payload) => {
		commit('companyRequest');
		companyService.removeTeamMember(payload)
			.then((result) => {
				commit('companySuccess', result.data);
			},
			(errors) => {
				commit('companyError', errors);
			})
	},
	resetState: ({ commit }) => {
		commit('companyRequest');
		commit('companySuccess', {});
	},
	getCoordinator: ({ commit }, id) => {
		commit('companyRequest');
		companyService.getCoordinator(id)
			.then((result) => {
				commit('coordinatorSuccess', result.data);
			},
				(errors) => {
					commit('companyError', errors);
				});
	},
	getRating: ({ commit }, id) => {
		commit('companyRequest');
		companyService.getRating(id)
			.then((result) => {
				commit('ratingSuccess', result.data);
			},
				(errors) => {
					commit('companyError', errors);
				});
	},
	getCompanyByName: ({ commit }, val) => {
		return new Promise((resolve, reject) => {
			commit('companyRequest');
			companyService.getCompanyByName(val)
				.then((result) => {
					commit('companyList', result.data);
					resolve(result.data);
				},
					(errors) => {
						commit('companyError', errors);
						reject();
					});
		});
	},
};

const mutations = {
	companyRequest: (companyState) => {
		companyState.status = 'attempting request for company data';
	},
	companySuccess: (companyState, companyResp) => {
		companyState.status = 'success';
		Vue.set(companyState, 'company', companyResp);
	},
	companyList: (companyState, list) => {
		companyState.status = 'success';
		Vue.set(companyState, 'list', list);
	},
	companyError: (companyState) => {
		companyState.status = 'error';
	},
	companyLogout: (companyState) => {
		companyState.status = '';
	},
	clientMembers: (companyState, list) => {
		companyState.status = 'success';
		Vue.set(companyState, 'members', list);
	},
	companyLoading: (companyState, response) => {
		companyState.loadingStatus = response;
	},
	coordinatorSuccess: (companyState, coordinatorResp) => {
		companyState.status = 'success';
		Vue.set(companyState, 'coordinator', coordinatorResp);
	},
	ratingSuccess: (companyState, response) => {
		companyState.state = 'success'
		Vue.set(companyState, 'rating', response.data);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};