import service from '../../services/factory-service';
import Vue from 'vue';

const referrerService = service.get("referrer");

const state = { 
  referrer: {}, 
  status: '', 
  list: [], 
  tally: { Referrers: [], Count: [] },
  criteria: {},
  paginated: {},
};

const getters = {
  referrer: (referrertate) => referrertate.referrer,
  list: (referrertate) => referrertate.list,
  tally: (referrertate) => referrertate.tally,
  criteria: (referrertate) => referrertate.criteria,
  paginated: (referrertate) => referrertate.paginated,
};

const actions = {
  get: ({ commit }) => {
    commit('referrerRequest');
    referrerService.get()
      .then((result) => {
        commit('referrerList', result.data);
      },
        (errors) => {
          commit('referrerError', errors);
        });
  },
  create: ({commit, dispatch}, referrer) => {
    commit('referrerRequest');
    referrerService.create(referrer)
      .then((result) => {
        commit('referrerSuccess', result.data);
        dispatch('referrer/get', null, { root: true });
      },
        (errors) => {
          commit('referrerError', errors);
        });
  },
  edit: ({commit, dispatch}, referrer) => {
    commit('referrerRequest');
    referrerService.edit(referrer)
      .then((result) => {
        commit('referrerSuccess', result.data);
        dispatch('referrer/get', null, { root: true });
      },
        (errors) => {
          commit('referrerError', errors);
        });
  },
  remove: ({commit, dispatch}, id) => {
    commit('referrerRequest');
    referrerService.remove(id)
      .then((result) => {
        commit('referrerSuccess', result.data);
        dispatch('referrer/get', null, { root: true });
      },
        (errors) => {
          commit('referrerError', errors);
        });
  },
  getTally: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('referrerRequest');
      referrerService.getTally()
        .then((result) => {
          commit('referrerTally', result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('referrerError', errors);
            reject();
          });
      });
  },
  getByCriteria: ({ commit }, payload) => {
    commit('referrerRequest');
    referrerService.getByCriteria(payload)
      .then((result) => {
        commit('referrerPaginated', result.data);
      },
        (errors) => {
          commit('referrerError', errors);
        });
  },
  getByName: ({ commit }, val) => {
    commit('referrerRequest');
    referrerService.getByName(val)
      .then((result) => {
        commit('referrerList', result.data);
      },
        (errors) => {
          commit('referrerError', errors);
        });
  },
  saveCriteria: ({ commit }, payload) => {
    localStorage.setItem(payload.key, JSON.stringify(payload.content));
    commit('referrerCriteria', payload.content);
  },
  getCriteria: ({ commit }, key) => {
    let criteria = JSON.parse(localStorage.getItem(key));

    if (criteria === undefined || criteria == null) {
      criteria = {
        startDate: null,
        endDate: null,
        referrers: [],
      };
    }

    commit('referrerCriteria', criteria);
  },
  resetCriteria: ({ commit }, key) => {
    let criteria = {
      startDate: null,
      endDate: null,
      referrers: [],
    };

    localStorage.setItem(key, JSON.stringify(criteria));
    commit('referrerCriteria', criteria);
  },
  exportReport: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('referrerRequest');
      referrerService.exportReport(payload)
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Referrer_Report.xlsx');
          document.body.appendChild(link);
          link.click();
          resolve();
        },
          (errors) => {
            commit('referrerError', errors);
            reject();
          });
    });
  },
};

const mutations = {
  referrerRequest: (referrertate) => {
    referrertate.status = 'attempting request for referrer';
  },
  referrerSuccess: (referrertate, response) => {
    referrertate.status = 'success';
    Vue.set(referrertate, 'referrer', response);
  },
  referrerList: (referrertate, list) => {
    referrertate.status = 'success';
    Vue.set(referrertate, 'list', list);
  },
  referrerTally: (referrertate, tally) => {
    referrertate.status = 'success';
    Vue.set(referrertate, 'tally', tally);
  },
  referrerError: (referrertate) => {
    referrertate.status = 'error';
  },
  referrerPaginated: (referrertate, list) => {
    referrertate.status = 'success';
    Vue.set(referrertate, 'paginated', list);
  },
  referrerCriteria: (referrertate, criteria) => {
    Vue.set(referrertate, 'criteria', criteria);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};