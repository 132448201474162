import Client from './clients/axios-client';
const resource = '/health';

export default {
	getIdentity() {
		return Client.get(`${resource}/identity`);
	},
  getMember() {
		return Client.get(`${resource}/member`);
	},
	getMessage() {
		return Client.get(`${resource}/message`);
	},
	getNotification() {
		return Client.get(`${resource}/notification`);
	},
	getPayment() {
		return Client.get(`${resource}/payment`);
	},
	getProject() {
		return Client.get(`${resource}/project`);
	},
};