<template>
  <v-app id="adminpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
    <router-view />
  </v-app>

</template>

<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    countDown: 3600000,
  }),

  methods: {
    countDownTimer() {
      let timerId = setInterval(() => {
        this.countDown -= 1
        if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

        if (this.countDown < 1) {
          clearInterval(timerId);

          this.$store.dispatch("auth/authLogout").then(() => {
            this.$router.push("/authentication/login");
          });
        }
        this.countDownTimer()
      }, 1000);
    }
  },

  created() {
    //this.countDownTimer();
  },
};
</script>
