import service from '../../services/factory-service';
import Vue from 'vue';

const teamService = service.get("team");
const userService = service.get("users");

const state = { team: {}, status: '', list: [], actives: [], members: [], coordinator: {}, count: 0, rating: 0, paginated: {} };

const getters = {
	team: (teamState) => teamState.team,
	forApproval: (teamState) => teamState.forApproval,
	list: (teamState) => teamState.list,
	actives: (teamState) => teamState.actives,
	members: (teamState) => teamState.members,
	coordinator: (teamState) => teamState.coordinator,
	count: (teamState) => teamState.count,
	rating: (teamState) => teamState.rating,
	paginated: (teamState) => teamState.paginated,
};

const actions = {
	get: ({ commit }, id) => {
		commit('teamRequest');
		teamService.getTeam(id)
			.then((result) => {
				commit('teamDetails', result.data);
			},
				(errors) => {
					commit('teamError', errors);
				});
	},
	getTeamRating: ({ commit }, id) => {
		commit('teamRequest');
		teamService.getTeamRating(id)
			.then((result) => {
				commit('teamRating', result.data);
			},
				(errors) => {
					commit('teamError', errors);
				});
	},
	getList: ({ commit }, userid) => {
		commit('teamRequest');
		teamService.getUserTeams(userid)
			.then((result) => {
				commit('teamList', result.data);
			},
				(errors) => {
					commit('teamError', errors);
				});
	},
	getTeamCount: ({ commit }) => {
		commit('teamRequest');
		teamService.getUserTeamCount()
			.then((result) => {
				commit('teamCount', result.data);
			},
				(errors) => {
					commit('teamError', errors);
				});
	},
	getListWithMatch: ({ commit }, payload) => {
		commit('teamRequest');
		teamService.getUserTeamsMatching(payload)
			.then((result) => {
				commit('teamList', result.data);
			},
				(errors) => {
					commit('teamError', errors);
				});
	},
	getActives: ({ commit }, userid) => {
		commit('teamRequest');
		teamService.getUserTeams(userid)
			.then((result) => {
				commit('teamActives', result.data);
			},
				(errors) => {
					commit('teamError', errors);
				});
	},
	searchMembers: ({ commit }, name) => {
		return new Promise((resolve, reject) => {
			commit('teamRequest');
			userService.searchTalent(name)
				.then((result) => {
					commit('teamMembers', result.data);
					resolve();
				},
					(errors) => {
						commit('teamError', errors);
						reject(errors);
					});
		});
	},
	resetSearchMember: ({ commit }) => {
		commit('teamMembers', []);
	},
	addTeamMember: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('teamRequest');
      teamService.addTeamMember(payload)
        .then((result) => {
          commit('teamDetails', result.data);
          resolve();
        },
          (errors) => {
            commit('teamError', errors);
            reject(errors);
          });
    });
	},
	removeTeamMember: ({ commit }, payload) => {
		commit('teamRequest');
		teamService.removeTeamMember(payload)
			.then((result) => {
				commit('teamDetails', result.data);
			},
				(errors) => {
					commit('teamError', errors);
				})
	},
	createTeam: ({ commit, dispatch }, payload) => {
		commit('teamRequest');
		teamService.createTeam(payload)
			.then((result) => {
				commit('teamDetails', result.data);
				dispatch('team/getList', payload.UserID, { root: true });
			},
				(errors) => {
					commit('teamError', errors);
				});
	},
	updateTeamAvailability: ({ commit }, payload) => {
		commit('teamRequest');
		teamService.updateTeamAvailability(payload)
			.then((result) => {
				commit('teamDetails', result.data);
			},
				(errors) => {
					commit('teamError', errors);
				});
	},
	getCoordinator: ({ commit }, id) => {
		commit('teamRequest');
		teamService.getCoordinator(id)
			.then((result) => {
				commit('teamCoordinator', result.data);
			},
				(errors) => {
					commit('teamError', errors);
				});
	},
	resetTeamState: ({ commit }) => {
		commit('teamRequest');
		commit('teamDetails', {});
	},
	getOpenTeams: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			commit('teamRequest');
			teamService.getOpenTeams(payload)
				.then((result) => {
					commit('teamPaginated', result.data);
					resolve(result.data);
				},
					(errors) => {
						commit('teamError', errors);
						reject(errors);
					})
		});
	},
	openMyTeam: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			commit('teamRequest');
			teamService.openMyTeam(id)
				.then((result) => {
					resolve(result.data);
				},
					(errors) => {
						commit('teamError', errors);
						reject(errors);
					})
		});
	},
	applyOnATeam: ({ commit }, id) => {
		return new Promise((resolve, reject) => {
			commit('teamRequest');
			teamService.applyOnATeam(id)
				.then(() => {
					commit('teamSuccess');
					resolve();
				},
					(errors) => {
						commit('teamError', errors);
						reject(errors);
					})
		});
	},
	removeMemberApplication: ({ commit }, payload) => {
		commit('teamRequest');
		teamService.removeMemberApplication(payload)
			.then((result) => {
				commit('teamDetails', result.data);
			},
				(errors) => {
					commit('teamError', errors);
				})
	},
	addFillerMember: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			commit('teamRequest');
			teamService.addFillerMember(payload)
				.then((result) => {
					commit('teamDetails', result.data);
					resolve();
				},
					(errors) => {
						commit('teamError', errors);
						reject();
					});
		});
	},
	updateFillterMemberSkills: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			commit('teamRequest');
			teamService.updateFillterMemberSkills(payload)
				.then((result) => {
					commit('teamDetails', result.data);
					resolve();
				},
					(errors) => {
						commit('teamError', errors);
						reject();
					});
		});
	},
};

const mutations = {
	teamRequest: (teamState) => {
		teamState.status = 'attempting request for team profile data';
	},
	teamSuccess: (teamState) => {
		teamState.status = 'success';
	},
	teamDetails: (teamState, details) => {
		teamState.status = 'success';
		Vue.set(teamState, 'team', details);
	},
	teamActives: (teamState, list) => {
		teamState.status = 'success';
		Vue.set(teamState, 'actives', list);
	},
	teamMembers: (teamState, list) => {
		teamState.status = 'success';
		Vue.set(teamState, 'members', list);
	},
	teamList: (teamState, list) => {
		teamState.status = 'success';
		Vue.set(teamState, 'list', list);
	},
	teamCount: (teamState, count) => {
		teamState.status = 'success';
		Vue.set(teamState, 'count', count);
	},
	teamCoordinator: (teamState, resp) => {
		teamState.status = 'success';
		Vue.set(teamState, 'coordinator', resp);
	},
	teamPaginated: (teamState, resp) => {
		teamState.status = 'success';
		Vue.set(teamState, 'paginated', resp);
	},
	teamError: (teamState) => {
		teamState.status = 'error';
	},
	teamRating: (teamState, rating) => {
		teamState.rating = rating;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};