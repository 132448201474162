import Client from './clients/axios-client';
const resource = '/api';

export default {
	login(credentials) {
		return Client.post(`${resource}/authentication/login`, credentials);
	},
	facebookLogin(token) {
		return Client.post(`${resource}/externalauthentication/facebook`, token);
	},
	googleLogin(token) {
		return Client.post(`${resource}/externalauthentication/google`, token);
	},
	msalLogin(token) {
		return Client.post(`${resource}/externalauthentication/microsoft`, token);
	},
	register(payload) {
		return Client.post(`${resource}/account`, payload);
	},
	createAdmin(payload) {
		return Client.post(`${resource}/account/adauth`, payload);
	},
	fbRegister(payload) {
		return Client.post(`${resource}/account/fbauth`, payload);
	},
	gRegister(payload) {
		return Client.post(`${resource}/account/gauth`, payload);
	},
	adRegister(payload) {
		return Client.post(`${resource}/account/adauth`, payload);
	},
	activate(payload) {
		return Client.post(`${resource}/profile/activate`, payload);
	},
	reject(payload) {
		return Client.post(`${resource}/profile/reject`, payload);
	},
	reset(payload) {
		return Client.post(`${resource}/authentication/reset`, payload);
	},
	verifyemail(payload) {
		return Client.post(`${resource}/account/verifyemail`, payload);
	},
	forgotpassword(payload) {
		return Client.post(`${resource}/account/forgotpassword`, payload);
	},
	submitnewpassword(payload) {
		return Client.post(`${resource}/authentication/validatepasswordtoken`, payload);
	},
	getUserTfaConfig() {
		return Client.get(`${resource}/account/tfaconfig`);
	},
	updateUserTfaConfig(payload) {
		return Client.post(`${resource}/account/tfaconfig`, payload);
	},
	requestTfaToken(payload) {
		return Client.post(`${resource}/account/tfarequest`, payload);
	},
	tfaLogin(payload) {
		return Client.post(`${resource}/authentication/twofactorlogin`, payload);
	},
	emailExists(payload) {
		return Client.get(`${resource}/account/emailExists/${payload}`);
	},
	adminVerifyemail(payload) {
		return Client.post(`${resource}/account/adminVerifyemail`, payload);
	},
	batchRegistration(request) {
    const formData = new FormData();
		request.Files.forEach((file) => { formData.append('files', file); });
    return Client.post(`${resource}/account/batch`, formData, 
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
	individualRegisterUser(payload) {
		return Client.post(`${resource}/account/batch/reg`, payload);
	},
};