import service from '../../services/factory-service';
import Vue from 'vue';

const messengerService = service.get("messenger");

const state = { status: '', list: [], topic: {}, };

const getters = {
	list: (messageState) => messageState.list,
  topic: (messageState) => messageState.topic,
};

const actions = {
	getAdminTopics: ({ commit }) => {
		commit('messageRequest');
		messengerService.getAdminTopics()
			.then((result) => {
				commit('messageSuccess', result.data);
			},
				(errors) => {
					commit('messageError', errors);
				});
	},
  getTopic: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit('messageRequest');
      messengerService.getTopic(id)
        .then((result) => {
          commit('messageTopic', result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('messageError', errors);
            reject(errors);
          });
    });
	},
  postMessage: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('messageRequest');
      messengerService.postMessage(payload)
        .then((result) => {
          commit('messageTopic', result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('messageError', errors);
            reject(errors);
          });
    });
  },
};

const mutations = {
	messageRequest: (messageState) => {
    messageState.status = 'attempting request for skill profile data';
  },
  messageSuccess: (messageState, resp) => {
    messageState.status = 'success';
    Vue.set(messageState, 'list', resp);
  },
  messageTopic: (messageState, topic) => {
    messageState.status = 'success';
    Vue.set(messageState, 'topic', topic);
  },
  messageError: (messageState) => {
    messageState.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};