import service from '../../services/factory-service';
//import { EventBus } from '../../event.bus';

const authService = service.get("auth");
const state = {
  token: localStorage.getItem('auth-token') || '',
  status: '',
  type: localStorage.getItem('auth-type') || '',
  tfaConfig: {},
  list: [],
};

const getters = {
  isAuthenticated: (authState) => !!authState.token,
  authStatus: (authState) => authState.status,
  authToken: (authState) => authState.token,
  authType: (authState) => authState.type,
  status: (authState) => authState.status,
  tfaConfig: (authState) => authState.tfaConfig,
  list: (authState) => authState.list,
};

const actions = {
  register: ({ commit, dispatch }, registration) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.register(registration)
        .then((result) => {
          localStorage.setItem('auth-token', result.data.auth_token);
          localStorage.setItem('auth-type', result.data.type);
          commit('authSuccess', result.data.auth_token);
          commit('authType', result.data.type);
          //EventBus.$emit('logged-in', result.data.id);
          dispatch('user/userRequest', result.data.id, { root: true });
          resolve(result.data);
        },
          (errors) => {
            commit('authError', errors);
            localStorage.removeItem('auth-token');
            localStorage.removeItem('auth-type');
            reject(errors);
          });
    });
  },
  fbRegister: ({ commit, dispatch }, registration) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.fbRegister(registration)
        .then((result) => {
          localStorage.setItem('auth-token', result.data.auth_token);
          localStorage.setItem('auth-type', result.data.type);
          commit('authSuccess', result.data.auth_token);
          commit('authType', result.data.type);
          //EventBus.$emit('logged-in', result.data.id);
          dispatch('user/userRequest', result.data.id, { root: true });
          resolve(result.data);
        },
          (errors) => {
            commit('authError', errors);
            localStorage.removeItem('auth-token');
            localStorage.removeItem('auth-type');
            reject(errors);
          });
    });
  },
  gRegister: ({ commit, dispatch }, registration) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.gRegister(registration)
        .then((result) => {
          localStorage.setItem('auth-token', result.data.auth_token);
          localStorage.setItem('auth-type', result.data.type);
          commit('authSuccess', result.data.auth_token);
          commit('authType', result.data.type);
          //EventBus.$emit('logged-in', result.data.id);
          dispatch('user/userRequest', result.data.id, { root: true });
          resolve(result.data);
        },
          (errors) => {
            commit('authError', errors);
            localStorage.removeItem('auth-token');
            localStorage.removeItem('auth-type');
            reject(errors);
          });
    });
  },
  adRegister: ({ commit, dispatch }, registration) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.adRegister(registration)
        .then((result) => {
          localStorage.setItem('auth-token', result.data.auth_token);
          localStorage.setItem('auth-type', result.data.type);
          commit('authSuccess', result.data.auth_token);
          commit('authType', result.data.type);
          //EventBus.$emit('logged-in', result.data.id);
          dispatch('user/userRequest', result.data.id, { root: true });
          resolve(result.data);
        },
          (errors) => {
            commit('authError', errors);
            localStorage.removeItem('auth-token');
            localStorage.removeItem('auth-type');
            reject(errors);
          });
    });
  },
  adminRegister: ({ commit }, registration) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.createAdmin(registration)
        .then((result) => {
          commit('requestSuccess');
          resolve(result.data);
        },
          (errors) => {
            commit('authError', errors);
            reject(errors);
          });
    });
  },
  authRequest: ({ commit, dispatch }, credentials) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.login(credentials)
        .then((result) => {
          localStorage.setItem('auth-token', result.data.auth_token);
          localStorage.setItem('auth-type', result.data.type);
          commit('authSuccess', result.data.auth_token);
          commit('authType', result.data.type);
          //EventBus.$emit('logged-in', result.data.id);
          dispatch('user/userRequest', result.data.id, { root: true });
          resolve(result.data);
        },
          (errors) => {
            commit('authError', errors);
            localStorage.removeItem('auth-token');
            localStorage.removeItem('auth-type');
            reject(errors);
          });
    });
  },
  tfaAuthRequest: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.tfaLogin(payload)
        .then((result) => {
          localStorage.setItem('auth-token', result.data.auth_token);
          localStorage.setItem('auth-type', result.data.type);
          commit('authSuccess', result.data.auth_token);
          commit('authType', result.data.type);
          //EventBus.$emit('logged-in', result.data.id);
          dispatch('user/userRequest', result.data.id, { root: true });
          resolve(result.data);
        },
          (errors) => {
            commit('authError', errors);
            localStorage.removeItem('auth-token');
            localStorage.removeItem('auth-type');
            reject(errors);
          });
    });
  },
  facebookAuthRequest: ({ commit, dispatch }, accessToken) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.facebookLogin(accessToken)
        .then((result) => {
          localStorage.setItem('auth-token', result.data.auth_token);
          localStorage.setItem('auth-type', result.data.type);
          commit('authSuccess', result.data.auth_token);
          commit('authType', result.data.type);
          //EventBus.$emit('logged-in', result.data.id);
          dispatch('user/userRequest', result.data.id, { root: true });
          resolve(result.data);
        },
          (errors) => {
            commit('authError', errors);
            localStorage.removeItem('auth-token');
            localStorage.removeItem('auth-type');
            reject(errors);
          });
    });
  },
  googleAuthRequest: ({ commit, dispatch }, accessToken) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.googleLogin(accessToken)
        .then((result) => {
          localStorage.setItem('auth-token', result.data.auth_token);
          localStorage.setItem('auth-type', result.data.type);
          commit('authSuccess', result.data.auth_token);
          commit('authType', result.data.type);
          //EventBus.$emit('logged-in', result.data.id);
          dispatch('user/userRequest', result.data.id, { root: true });
          resolve(result.data);
        },
          (errors) => {
            commit('authError', errors);
            localStorage.removeItem('auth-token');
            localStorage.removeItem('auth-type');
            reject(errors);
          });
    });
  },
  msalAuthRequest: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.msalLogin(payload)
        .then((result) => {
          localStorage.setItem('auth-token', result.data.auth_token);
          localStorage.setItem('auth-type', result.data.type);
          commit('authSuccess', result.data.auth_token);
          commit('authType', result.data.type);
          //EventBus.$emit('logged-in', result.data.id);
          dispatch('user/userRequest', result.data.id, { root: true });
          resolve(result.data);
        },
          (errors) => {
            commit('authError', errors);
            localStorage.removeItem('auth-token');
            localStorage.removeItem('auth-type');
            reject(errors);
          });
    });
  },
  activateUser: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.activate(payload)
        .then((result) => {
          dispatch('user/getForApproval', null, { root: true });
          resolve(result.data);
        },
          (errors) => {
            commit('authError', errors);
            reject(errors);
          });
    });
  },
  rejectUser: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.reject(payload)
        .then((result) => {
          dispatch('user/getForApproval', null, { root: true });
          resolve(result.data);
        },
          (errors) => {
            commit('authError', errors);
            reject(errors);
          });
    });
  },
  resetPassword: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.reset(payload)
        .then((result) => {
          localStorage.setItem('auth-token', result.data.auth_token);
          localStorage.setItem('auth-type', result.data.type);
          commit('authSuccess', result.data.auth_token);
          commit('authType', result.data.type);
          //EventBus.$emit('logged-in', result.data.id);
          dispatch('user/userRequest', result.data.id, { root: true });
          resolve(result.data);
        },
          (errors) => {
            commit('authError', errors);
            localStorage.removeItem('auth-token');
            localStorage.removeItem('auth-type');
            reject(errors);
          });
    });
  },
  authLogout: ({ commit, dispatch }) => {
    return new Promise((resolve) => {
      commit('authLogout');
      localStorage.removeItem('auth-token');
      localStorage.removeItem('auth-type');
      dispatch('user/userLogout', null, { root: true });
      resolve();
    });
  },
  verifyEmail: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.verifyemail(payload)
        .then(() => {
          commit('authSuccess', null);
          resolve();
        },
          (errors) => {
            commit('authError', errors);
            reject(errors);
          });
    });
  },
  forgotpassword: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.forgotpassword(payload)
        .then(() => {
          commit('authSuccess', null);
          resolve();
        },
          (errors) => {
            commit('authError', errors);
            reject(errors);
          });
    });
  },
  submitnewpassword: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.submitnewpassword(payload)
        .then(() => {
          commit('authSuccess', null);
          resolve();
        },
          (errors) => {
            commit('authError', errors);
            reject(errors);
          });
    });
  },
  getUserTfaConfig: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.getUserTfaConfig()
        .then((response) => {
          commit('authTfaConfig', response.data);
          resolve();
        },
          (errors) => {
            commit('authError', errors);
            reject(errors);
          });
    });
  },
  updateUserTfaConfig: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.updateUserTfaConfig(payload)
        .then(() => {
          commit('authTfaConfig', payload);
          resolve();
        },
          (errors) => {
            commit('authError', errors);
            reject(errors);
          });
    });
  },
  requestTfaToken: ({ commit , payload}) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.requestTfaToken(payload)
        .then(() => {
          commit('requestSuccess');
          resolve();
        },
          (errors) => {
            commit('authError', errors);
            reject(errors);
          });
    });
  },
  checkEmailExists: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.emailExists(payload)
        .then((response) => {
          commit('requestSuccess');
          resolve(response);
        },
          (errors) => {
            commit('authError', errors);
            reject(errors);
          });
    });
  },
  adminVerifyemail: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.adminVerifyemail(payload)
        .then(() => {
          commit('requestSuccess');
          resolve();
        },
          (errors) => {
            commit('authError', errors);
            reject(errors);
          });
    });
  },
  batchRegistration: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      authService.batchRegistration(payload)
        .then((response) => {
          commit('requestList', response.data);
          resolve();
        },
          (errors) => {
            commit('authError', errors);
            reject(errors);
          });
    });
  },
  individualRegisterUser: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      authService.individualRegisterUser(payload)
        .then((response) => {
          resolve(response.data);
        },
          (errors) => {
            commit('authError', errors);
            reject(errors);
          });
    });
  },
};

const mutations = {
  authRequest: (authState) => {
    authState.status = 'attempting authentication request';
  },
  authSuccess: (authState, authToken) => {
    authState.status = 'authentication succeeded';
    authState.token = authToken;
  },
  requestSuccess: (authState) => {
    authState.status = 'authentication succeeded';
  },
  requestList: (authState, list) => {
    authState.list = list;
  },
  authType: (authState, authType) => {
    authState.type = authType;
  },
  authError: (authState) => {
    authState.status = 'error';
  },
  authLogout: (authState) => {
    authState.token = '';
  },
  authTfaConfig: (authState, response) => {
    authState.tfaConfig = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

//https://vuejs.org/v2/guide/security.html