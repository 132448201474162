import service from '../../services/factory-service';
import Vue from 'vue';

const invoiceService = service.get("companyinvoice");
const paymentService = service.get("payment");

const state = { invoice: {}, status: '', list: [], loadingStatus: false, paginated: {}, criteria: {} };

const getters = {
  invoice: (invoiceState) => invoiceState.invoice,
  list: (invoiceState) => invoiceState.list,
  status: (invoiceState) => invoiceState.status,
  loadingStatus: (invoiceState) => invoiceState.loadingStatus,
  paginated: (invoiceState) => invoiceState.paginated,
  criteria: (invoiceState) => invoiceState.criteria,
};

const actions = {
  createProjectInvoice: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      invoiceService.createProjectInvoice(payload)
        .then((result) => {
          commit('invoiceList', result.data);
          resolve();
        },
          (errors) => {
            commit('invoiceError', errors);
            reject(errors);
          });
    });
  },
  getProcessingInvoice: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      invoiceService.getInvoiceList(payload)
        .then((result) => {
          commit('invoicePaginated', result.data);
          resolve();
        },
          (errors) => {
            commit('invoiceError', errors);
            reject();
          });
    });
  },
  getProjectInvoice: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      invoiceService.getProjectInvoice(id)
        .then((result) => {
          commit('invoiceList', result.data);
          resolve();
        },
          (errors) => {
            commit('invoiceError', errors);
            reject();
          });
    });
  },
  getClientInvoiceList: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      commit('loadingInvoice', true);
      invoiceService.getClientInvoiceList(payload)
        .then((result) => {
          commit('invoicePaginated', result.data);
          commit('loadingInvoice', false);
          resolve();
        },
          (errors) => {
            commit('invoiceError', errors);
            commit('loadingInvoice', false);
            reject();
          });
    });
  },
  getInvoice: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      invoiceService.getInvoice(id)
        .then((result) => {
          commit('invoiceSuccess', result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('invoiceError', errors);
            reject();
          });
    });
  },
  getInvoiceForProcessing: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      invoiceService.getInvoiceForProcessing(id)
        .then((result) => {
          commit('invoiceSuccess', result.data);
          resolve();
        },
          (errors) => {
            commit('invoiceError', errors);
            reject();
          });
    });
  },
  downloadFile: ({ commit }, payload) => {
    commit('invoiceRequest');
    invoiceService.downloadFile(payload)
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', payload.name);
        document.body.appendChild(link);
        link.click();
      },
        (errors) => {
          commit('invoiceError', errors);
        });
  },
  removeFile: ({ commit }, payload) => {
    commit('invoiceRequest');
    invoiceService.removeFile(payload)
      .then((result) => {
        commit('invoiceSuccess', result.data);
      },
        (errors) => {
          commit('invoiceError', errors);
        });
  },
  uploadFile: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      invoiceService.uploadFile(payload)
        .then((result) => {
          commit('invoiceSuccess', result.data);
          resolve();
        },
          (errors) => {
            commit('invoiceError', errors);
            reject();
          });
    });
  },
  changeStatus: ({ commit }, payload) => {
    commit('invoiceRequest');
    invoiceService.changeInvoiceStatusDiscussion(payload)
      .then((result) => {
        commit('invoiceSuccess', result.data);
      },
        (errors) => {
          commit('invoiceError', errors);
        });
  },
  submitPayment: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      paymentService.submitPayment(payload)
        .then((result) => {
          commit('invoiceSuccess', result.data);
          resolve();
        },
          (errors) => {
            commit('invoiceError', errors);
            reject(errors);
          });
    });
  },
  exportReport: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('invoiceRequest');
      invoiceService.exportReport(payload)
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Client_Invoice_Released.xlsx');
          document.body.appendChild(link);
          link.click();
          resolve();
        },
          (errors) => {
            commit('invoiceError', errors);
            reject();
          });
    });
  },
};

const mutations = {
  invoiceRequest: (invoiceState) => {
    invoiceState.status = 'attempting request for invoice data';
  },
  invoiceSuccess: (invoiceState, response) => {
    invoiceState.status = 'success';
    Vue.set(invoiceState, 'invoice', response);
  },
  invoiceList: (invoiceState, list) => {
    invoiceState.status = 'success';
    Vue.set(invoiceState, 'list', list);
  },
  invoicePaginated: (invoiceState, list) => {
    invoiceState.status = 'success';
    Vue.set(invoiceState, 'paginated', list);
  },
  invoiceError: (invoiceState, errors) => {
    console.debug(errors);
    invoiceState.status = 'error';
  },
  loadingInvoice: (invoiceState, response) => {
    invoiceState.loadingStatus = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};