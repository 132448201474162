import Client from './clients/axios-client';
import { serialize } from 'object-to-formdata';
const resource = '/api/topic';

export default {
	getAdminTopics() {
		return Client.get(`${resource}/all`);
	},
	getTopic(id) {
		return Client.get(`${resource}/details/${id}`);
	},
	postMessage(payload) {
		const _formData = serialize(payload);
		if (payload.Attachment !== undefined) {
			payload.Attachment.forEach((file) => { _formData.append('Attachment', file); });
		}

		return Client.post(`${resource}`, _formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
	},
}