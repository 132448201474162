import service from '../../services/factory-service';
import Vue from 'vue';

const hcService = service.get("healthcheck");

const state = { 
    identity: '', 
    member: '', 
    message: '',
    notification: '',
    project: '',
    payment: '',
};

const getters = {
  identity: (hcState) => hcState.identity,
  member: (hcState) => hcState.member,
  message: (hcState) => hcState.message,
  notification: (hcState) => hcState.notification,
	project: (hcState) => hcState.project,
	payment: (hcState) => hcState.payment,
};

const actions = {
	checkIdentity: ({ commit }) => {
    commit('hcRequest');
    hcService.getIdentity()
      .then((result) => {
        commit('hcIdentitySuccess', result.data);
      },
        (errors) => {
          commit('hcError', errors);
        });
  },
	checkMember: ({ commit }) => {
    commit('hcRequest');
    hcService.getMember()
      .then((result) => {
        commit('hcMemberSuccess', result.data);
      },
        (errors) => {
          commit('hcError', errors);
        });
  },
	checkMessage: ({ commit }) => {
    commit('hcRequest');
    hcService.getMessage()
      .then((result) => {
        commit('hcMessageSuccess', result.data);
      },
        (errors) => {
          commit('hcError', errors);
        });
  },
	checkNotification: ({ commit }) => {
    commit('hcRequest');
    hcService.getNotification()
      .then((result) => {
        commit('hcNotificationSuccess', result.data);
      },
        (errors) => {
          commit('hcError', errors);
        });
  },
	checkPayment: ({ commit }) => {
    commit('hcRequest');
    hcService.getPayment()
      .then((result) => {
        commit('hcPaymentSuccess', result.data);
      },
        (errors) => {
          commit('hcError', errors);
        });
  },
	checkProject: ({ commit }) => {
    commit('hcRequest');
    hcService.getProject()
      .then((result) => {
        commit('hcProjectSuccess', result.data);
      },
        (errors) => {
          commit('hcError', errors);
        });
  },
};

const mutations = {
  hcRequest: (hcState) => {
    hcState.status = 'attempting request for skill profile data';
  },
  hcIdentitySuccess: (hcState, resp) => {
    hcState.status = 'success';
    Vue.set(hcState, 'identity', resp);
  },
	hcMemberSuccess: (hcState, resp) => {
    hcState.status = 'success';
    Vue.set(hcState, 'member', resp);
  },
	hcMessageSuccess: (hcState, resp) => {
    hcState.status = 'success';
    Vue.set(hcState, 'message', resp);
  },
	hcNotificationSuccess: (hcState, resp) => {
    hcState.status = 'success';
    Vue.set(hcState, 'notification', resp);
  },
	hcProjectSuccess: (hcState, resp) => {
    hcState.status = 'success';
    Vue.set(hcState, 'project', resp);
  },
	hcPaymentSuccess: (hcState, resp) => {
    hcState.status = 'success';
    Vue.set(hcState, 'payment', resp);
  },
  hcError: (hcState) => {
    hcState.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};