import Client from './clients/axios-client';
import { serialize } from 'object-to-formdata';
const resource = '/api/invoice';

export default {
	createProjectInvoice(payload) {
		const _formData = serialize(payload);
    if (payload.Attachments !== undefined) {
      payload.Attachments.forEach((file) => { _formData.append('Attachments', file); });
    }
    
    return Client.post(`${resource}`, _formData, 
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
	},
  updateProjectInvoice(payload) {
		const _formData = serialize(payload);
    if (payload.Attachments !== undefined) {
      payload.Attachments.forEach((file) => { _formData.append('Attachments', file); });
    }

    return Client.post(`${resource}/${payload.id}`, _formData, 
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
	},
  saveInvoiceDiscussion(payload) {
		return Client.put(`${resource}/${payload.id}`, payload);
  },
	getProjectInvoice(id) {
		return Client.get(`${resource}/project/${id}`);
	},
  getTalentInvoice(id) {
		return Client.get(`${resource}/${id}`);
  },
  downloadFile(payload) {
		return Client.get(`${resource}/${payload.id}/files/${payload.fileId}`, {
			responseType: 'blob'
		});
  },
  removeFile(payload) {
		return Client.delete(`${resource}/${payload.id}/files/${payload.fileId}`);
  },
  getInvoiceListForProcessing(payload) {
		return Client.post(`${resource}/processing`, payload);
  },
  getInvoiceForProcessing(id) {
		return Client.get(`${resource}/processing/${id}`);
  },
  changeInvoiceStatusDiscussion(payload) {
		return Client.put(`${resource}/processing/${payload.id}`, payload);
  },
  exportReport(payload) {
		return Client.post(`${resource}/export`, payload, {
			responseType: 'blob'
		});
  },
}