import service from '../../services/factory-service';
import Vue from 'vue';

const skillsService = service.get("skills");

const state = { skill: {}, status: '', forApproval: [], list: [], quickList: [] };

const getters = {
  skill: (skillState) => skillState.skill,
  forApproval: (skillState) => skillState.forApproval,
  list: (skillState) => skillState.list,
  quickList: (skillState) => skillState.quickList,
};

const actions = {
  getForApproval: ({ commit }) => {
    commit('skillRequest');
    skillsService.getSkillsByStatusId(1)
      .then((result) => {
        commit('skillForApproval', result.data);
      },
        (errors) => {
          commit('skillError', errors);
        });
  },
  getSkills: ({ commit }) => {
    commit('skillRequest');
    skillsService.getSkills()
      .then((result) => {
        commit('skillList', result.data);
      },
        (errors) => {
          commit('skillError', errors);
        });
  },
  getSkillsBatch: ({ commit }, params) => {
    commit('skillRequest');
    skillsService.getSkillsBatch(params)
      .then((result) => {
        commit('skillsQuickList', result.data);
      },
        (errors) => {
          commit('skillError', errors);
        });
  },
  addSkill: ({commit, dispatch}, skill) => {
    commit('skillRequest');
    skillsService.createSkill(skill)
      .then((result) => {
        commit('skillSuccess', result.data);
        dispatch('skill/getSkills', null, { root: true });
      },
        (errors) => {
          commit('skillError', errors);
        });
  },
  editSkill: ({commit, dispatch}, skill) => {
    commit('skillRequest');
    skillsService.editSkill(skill)
      .then((result) => {
        commit('skillSuccess', result.data);
        dispatch('skill/getSkills', null, { root: true });
      },
        (errors) => {
          commit('skillError', errors);
        });
  },
  approveSkill: ({commit, dispatch}, id) => {
    commit('skillRequest');
    skillsService.approveSkill(id)
      .then((result) => {
        commit('skillSuccess', result.data);
        dispatch('skill/getSkills', null, { root: true });
      },
        (errors) => {
          commit('skillError', errors);
        });
  },
  rejectSkill: ({commit, dispatch}, id) => {
    commit('skillRequest');
    skillsService.rejectSkill(id)
      .then((result) => {
        commit('skillSuccess', result.data);
        dispatch('skill/getSkills', null, { root: true });
      },
        (errors) => {
          commit('skillError', errors);
        });
  },
  removeSkill: ({commit, dispatch}, id) => {
    commit('skillRequest');
    skillsService.removeSkill(id)
      .then((result) => {
        commit('skillSuccess', result.data);
        dispatch('skill/getSkills', null, { root: true });
      },
        (errors) => {
          commit('skillError', errors);
        });
  },
};

const mutations = {
  skillRequest: (skillState) => {
    skillState.status = 'attempting request for skill profile data';
  },
  skillSuccess: (skillState, skillResp) => {
    skillState.status = 'success';
    Vue.set(skillState, 'profile', skillResp);
  },
  skillsQuickList: (skillState, list) => {
    skillState.status = 'success';
    Vue.set(skillState, 'quickList', list);
  },
  skillForApproval: (skillState, list) => {
    skillState.status = 'success';
    Vue.set(skillState, 'forApproval', list);
  },
  skillList: (skillState, list) => {
    skillState.status = 'success';
    Vue.set(skillState, 'list', list);
  },
  skillError: (skillState) => {
    skillState.status = 'error';
  },
  skillLogout: (skillState) => {
    skillState.status = '';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};